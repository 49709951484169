<template>
  <div class="splitview-container" :key="hasMobileView">
    <splitpanes
      horizontal
      class="default-theme">
      <pane>
        <splitpanes
          :vertical="hasMobileView"
          :horizontal="hasMobileView">
          <pane :size="viewRatio" :min-size="viewRatio" :max-size="viewRatio">
            <slot />
          </pane>
          <pane>
            <default-layout :wide="true">
              <component
                v-if="component"
                :breadcrumb="false"
                :splitview-mode="true"
                :is="component.vueComponent"
                :category="component.category"
                @selectElement="resolveAndSelectElement">
              </component>
            </default-layout>
          </pane>
        </splitpanes>
      </pane>
      <pane
        min-size="15"
        max-size="15"
        size="15"
        class="preview"
        v-if="childComponents.length > 2">
        <folder-container :horizontal="true">
          <template
            v-for="(component, componentIndex) in childComponents">
            <folder-element
              :hide-name="true"
              :key="componentIndex"
              :splitview-mode="true"
              :emitClickEvent="true"
              v-if="componentIndex > 1"
              :element="component.category"
              :tooltip="component.category.categoryname"
              @click.prevent="selectElement(component)">
            </folder-element>
          </template>
        </folder-container>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
  import {Pane, Splitpanes} from 'splitpanes'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'wide',
      'category',
      'breadcrumb',
      'childComponents',
    ],
    computed: {
      ...mapGetters({
        hasMobileView: 'app/hasMobileView',
        templateAttribute: 'config/templateAttribute',
      }),
      viewRatio() {
        let viewRatioAttribute = this.category?.attributes?.find(attr => attr.name === 'CMS-SplitView-Ratio')
        if (viewRatioAttribute && viewRatioAttribute.value) {
          return viewRatioAttribute.value.split(':')[0]
        }
        return 50
      }
    },
    data() {
      return {
        component: null,
      }
    },
    created() {
      // Select default element on the right panel
      if (this.childComponents && this.childComponents.length > 1) {
        this.selectElement(this.childComponents[1])
      }
    },
    methods: {
      async selectElement(component) {
        component.category = await this.$api.findCategory(component.category.id)
        this.component = component
      },
      async resolveAndSelectElement($event) {
        const templateAttr = $event.attributes?.find(attr => attr.name === this.templateAttribute)
        const componentType = this.$helper.resolveComponentTypeByCategory($event, templateAttr)
        const dynamicComponent = {
          category: $event,
          component: componentType
        }
        const resolvedComponents = this.$helper.resolveComponents([dynamicComponent], 'splitview')
        if (resolvedComponents && resolvedComponents.length > 0) {
          this.selectElement(resolvedComponents[0])
        }
      }
    },
    components: {
      Pane,
      Splitpanes,
    },
    inject: [
      '$helper',
      '$api',
    ]
  }
</script>

<style lang="scss" scoped>
  .splitview-container {
    @apply
      w-full
      h-full;

    .preview {
      @apply
        py-1
        px-4
        flex
        items-center
        overflow-y-auto;
    }
  }
</style>